<template>
  <div class="m-grid">
    <table class="table table-bordered table-filter" :class="{ 'table-hover': data.length }">
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column.name"
            @click="sortBy(column)"
            :class="[{ sortable: column.sortable }, { active: value && value.key === column.name }]"
          >
            {{ $t(column.name) }}
            <span
              v-if="$t(column.name)"
              class="arrow"
              :class="value && value.key === column.name ? value.direction.toLowerCase() : ''"
            >
            </span>
          </th>
        </tr>
      </thead>
      <tbody v-if="data.length">
        <tr v-for="row in data" :key="row.id" @click="followLink(row.onclick)">
          <td v-for="column in columns" :key="column.name" :class="column.class">
            <div v-if="isTypeList(row[column.name])" class="m-grid__actions">
              <div v-for="item in row[column.name].list" :key="item.id" class="m-grid__item">
                <badge v-if="item.badge" :content="item.badgeText" :badgeClass="item.badge" />
                <span v-if="item.text">{{ item.text }}</span>
                <button-gcr
                  v-if="item.button && !item.buttonTooltip"
                  :btnClass="item.button"
                  @btnclick.stop="item.onclick">
                </button-gcr>
                <button-gcr
                  v-if="item.button && item.buttonTooltip"
                  v-tooltip="item.buttonTooltip"
                  :btnClass="item.button"
                  @btnclick.stop="item.onclick">
                </button-gcr>
                <confirmation-popover
                  v-if="item.buttonProject === 'delete' && !isSAC"
                  btnClass="btn btn-delete btn-delete-sm"
                  btnTooltipText="deleteProjectConfirmationAcceptButton"
                  @confirm="removeProject(item.projectId, item.accreditationId)"
                  confirmTitle="deleteProjectConfirmationTitle"
                  confirmText="deleteProjectConfirmationText"
                  acceptButtonText="deleteProjectConfirmationAcceptButton"
                  cancelButtonText="deleteProjectConfirmationCancelButton"
                  @click.native.stop
                />
              </div>
            </div>
            <span v-else v-html="row[column.name]"></span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="100">{{ $t('noData') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Consts } from '@/_helpers/consts'
import Badge from '@/components/atoms/Badge'
import ButtonGcr from '@/components/atoms/Button'
import ConfirmationPopover from '@/components/molecules/ConfirmationPopover'
import { mapGetters } from 'vuex'
import deleteProject from '@/graphql/accreditations/projects/deleteProject.graphql'

require('../../utilities/table')
require('../../utilities/button')

export default {
  components: { Badge, ButtonGcr, ConfirmationPopover },
  props: {
    data: Array,
    columns: Array,
    value: {} // sort column (key) and direction
  },
  computed: {
    ...mapGetters({
      isSAC: Consts.GETTER_USER_ISSAC
    })
  },
  methods: {
    sortBy(column) {
      if (column.sortable) {
        this.value.key = column.name
        this.value.direction = this.value.direction === 'ASC' ? 'DESC' : 'ASC'
      }
    },
    followLink(link) {
      if (link.startsWith('/')) {
        this.$router.push(link)
      } else {
        window.location.href = link
      }
    },
    isTypeList(entry) {
      return entry && entry.type === 'list'
    },
    removeProject(projectId, accreditationId) {
      return this.$apollo
          .mutate({
            mutation: deleteProject,
            variables: {
              input: {
                accreditationId,
                projectId
              }
            }
          })
          .then(() => {
            this.$emit('update')
          })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../utilities/config';

.m-grid {
  margin: 15px 0;
  overflow-x: auto;

  .btn-download {
    margin-top: -10px;
  }
  .form-group {
    margin-bottom: 0;
    display: inline-block;
  }
  &__actions {
    display: flex;
    max-width: 180px;
    flex-wrap: wrap;
  }
  &__item {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
