<template>
  <span class="badge" :class="[badgeClass]">{{ content }}</span>
</template>

<script>
export default {
  props: ['content', 'badgeClass'],
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
@import 'node_modules/bootstrap/scss/badge';
.badge {
  font-size: 13px;
  font-weight: 500; //font-weight: 400;;
  display: inline-block;
  margin-right: 5px;
  &:empty {
    display: inline-block;
  }
  &-primary {
    color: gray('white');
  }
  &-warning {
    color: gray('white');
  }
  &-midgray {
    color: gray('white');
  }

  &-check {
    @extend .badge-primary;
    padding-left: 10px;
    padding-right: 10px;
    &::before {
      content: '';
      height: 11px;
      width: 11px;
      display: inline-block;
      background-image: url(../../assets/check_dark_gray.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      opacity: 0.5;
    }
  }
  &-exclamation {
    @extend .badge-danger;
    padding-left: 10px;
    padding-right: 10px;
    &::before {
      content: '';
      height: 11px;
      width: 11px;
      display: inline-block;
      background-image: url(../../assets/exclamation_dark_gray.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      opacity: 0.5;
    }
  }
  &-close {
    @extend .badge-danger;
    padding-left: 10px;
    padding-right: 10px;
    &::before {
      content: '';
      height: 11px;
      width: 11px;
      display: inline-block;
      background-image: url(../../assets/close_dark_gray.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      opacity: 0.5;
    }
  }
}
</style>
